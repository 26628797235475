<template>
  <v-container class="pa-0" fluid>
    <v-container class="pb-0">
      <!-- header -->
      <header-sheet />
    </v-container>

    <!-- highlights -->
    <section class="py-12">
      <v-container>
        <h1 class="text-h4 text-xl-h3 font-weight-black font-italic mb-4">
          DESTAQUES
        </h1>
      </v-container>

      <v-slide-group class="mb-6 mb-xl-12 mx-6" show-arrows>
        <v-slide-item v-for="n in 6" :key="n">
          <v-flex class="pa-1 pr-6">
            <v-card class="rounded-lg" color="surface">
              <v-responsive :aspect-ratio="16 / 9" />
            </v-card>

            <v-list-item class="px-0" style="max-width: 300px">
              <v-list-item-content>
                <v-list-item-title class="text-h6 text-xl-h5">
                  Title of new
                </v-list-item-title>

                <v-list-item-subtitle
                  class="text-body-1 text-xl-h6 font-weight-regular"
                >
                  Description of new
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-flex>
        </v-slide-item>
      </v-slide-group>
    </section>

    <!-- news -->
    <section class="altback py-12">
      <v-row
        class="align-center justify-center mb-12"
        style="margin-top: -72px"
        no-gutters
      >
        <v-sheet
          class="px-8 py-2 gradient"
          color="primary"
          style="transform: skewX(-10deg)"
          dark
        >
          <span class="text-h6 text-xl-h5"> O QUE JÁ ROLOU POR AQUI... </span>
        </v-sheet>
      </v-row>

      <v-container class="my-6">
        <v-row
          v-for="(item, index) in 4"
          class="mb-4 align-center"
          :key="index"
        >
          <v-col cols="12" sm="5" lg="4" xl="3">
            <v-card color="surface">
              <v-responsive :aspect-ratio="16 / 9" />
            </v-card>
          </v-col>

          <v-col cols="12" sm="7" lg="8" xl="9">
            <v-list-item class="px-0 mb-2">
              <v-list-item-content class="py-0">
                <v-list-item-title class="text-h5 text-xl-h4 font-weight-bold">
                  Title of new
                </v-list-item-title>

                <v-list-item-subtitle
                  class="text-body-1 text-xl-h6 font-weight-regular"
                >
                  00/00/00 00:00
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <div class="text text-body-1 text-xl-h6 font-weight-regular">
              Empresa de marketing esportivo foi a responsável pelas entregas
              comerciais e irá gerir o contrato da indústria com a equipe
              maringaense Após o anúncio oficial da equipe
            </div>

            <a class="text-body-1 text-xl-h6 font-weight-regular">Ler mais</a>
          </v-col>
        </v-row>

        <v-row class="justify-center" no-gutters>
          <v-btn color="gradient" large dark> Carregar mais </v-btn>
        </v-row>
      </v-container>
    </section>
  </v-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}
</style>